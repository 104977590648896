import React from 'react'
import shop8 from "../images/shop8.jpg"
import { Container, Row, Col } from 'react-bootstrap'
import stone1 from "../images/stone1.png"
import stone2 from "../images/stone2.png"
import stone3 from "../images/stone3.png"
import stone4 from "../images/stone4.png"
import stone5 from "../images/stone10.png"
import stone6 from "../images/stone6.png"
import stone7 from "../images/stone7.png"
import stone8 from "../images/stone8.png"
import stone9 from "../images/stone9.png"






const STONE_DATA = [
    { paymentlink: "https://topmate.io/sagar_shastri/1098101", image: stone3, price: "9999", title: "नीलम", desc: "निळ्या नीलम रत्नाचा प्रभाव त्वरित जाणवू शकतो. नीलम दगडाची अंगठी घातल्यानंतर केवळ एका दिवसात तुम्हाला संपत्ती, नशीब, पदोन्नती आणि संधी देऊ शकते. नीलम स्टोन अंगठी शत्रू, वाईट नजर, हेक्सिंग, मत्सर यापासून संरक्षण करण्यास मदत करते" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098102", image: stone1, price: "6999", title: "मोती", desc: "हे चंद्राचे दुष्परिणाम दूर करते, मन मजबूत करते आणि तणावग्रस्त माणसाच्या जीवनात भावनिक संतुलन आणते. हे झोपेच्या दरम्यान अस्वस्थता कमी करण्यास आणि निद्रानाश बरे करण्यास मदत करते. मोती उदासीनता, मानसिक विकार बरे करण्यास, स्मरणशक्ती सुधारण्यास मदत करते." },
    { paymentlink: "https://topmate.io/sagar_shastri/1098105", image: stone2, price: "1999", title: "मुंगा", desc: "मुंग्याचा दगड परिधान करणाऱ्याच्या वैवाहिक जीवनाला आणि जोडीदाराच्या दीर्घायुष्याला बळ देतो. रत्न धारण केल्याने परिधान करणार्‍यांचा आत्मविश्वास वाढेल आणि ते धैर्यवान बनतील. हे शारीरिक आरोग्यासाठी फायदेशीर आहे आणि अडथळे दूर करण्यास देखील मदत करते." },
    { paymentlink: "https://topmate.io/sagar_shastri/1098106", image: stone5, price: "11999", title: "मुंगा अंगुठी  ", desc: "मुंग्याचा दगड परिधान करणाऱ्याच्या वैवाहिक जीवनाला आणि जोडीदाराच्या दीर्घायुष्याला बळ देतो. रत्न धारण केल्याने परिधान करणार्‍यांचा आत्मविश्वास वाढेल आणि ते धैर्यवान बनतील. हे शारीरिक आरोग्यासाठी फायदेशीर आहे आणि अडथळे दूर करण्यास देखील मदत करते." },
    { paymentlink: "https://topmate.io/sagar_shastri/1098108", image: stone7, price: "12999", title: "माणिक्य ", desc: "संशोधन उद्योगातील विद्यार्थी आणि व्यावसायिक दोघांसाठी हे खरोखर फायदेशीर आहे. हेसोनाइट एखाद्याच्या करिअरमध्ये प्रगती करते आणि त्यांची सामाजिक आणि आर्थिक स्थिती सुधारते. " },
    { paymentlink: "https://topmate.io/sagar_shastri/1098110", image: stone4, price: "20999", title: "पुष्कराज", desc: "मानसिक स्पष्टता वाढवा झोपेची गुणवत्ता चांगली मिळवा तणावाची पातळी कमी करा अधिक ऊर्जा मिळवा रोगप्रतिकार प्रणाली कार्य वाढवा रक्ताभिसरण सुधारणे अधिक आध्यात्मिक जागरूकता मिळवा" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098118", image: stone8, price: "15999", title: "पन्ना", desc: " हे तुम्हाला हुशार आणि बौद्धिक बनवते तुमची सर्जनशीलता वाढवते आर्थिक वाढीस मदत होते संवाद सुधारतो शारीरिक स्वास्थ्य आणि शक्ती सुधारते तुम्हाला नाव आणि प्रसिद्धी मिळविण्यात मदत करते" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098121", image: stone7, price: "14999", title: "माणिक्य अंगुठी ", desc: "जर एखाद्या व्यक्तीच्या लग्नाला उशीर होत असेल किंवा लग्नात सर्व प्रकारचे अडथळे येत असतील तर तुम्ही ज्योतिषाचा सल्ला घेऊन हिरा धारण करू शकता. हिरा धारण केल्याने व्यक्तीच्या आर्थिक समस्याही कमी होतात. याशिवाय ज्यांना कला, चित्रपट आणि संगीत क्षेत्रात यश मिळवायचे आहे त्यांनी हिरा घालू शकता." },
    { paymentlink: "https://topmate.io/sagar_shastri/1098123", image: stone9, price: "15999", title: "गोमेद", desc: "संशोधन उद्योगातील विद्यार्थी आणि व्यावसायिक दोघांसाठी हे खरोखर फायदेशीर आहे.हेसोनाइट एखाद्याच्या करिअरमध्ये प्रगती करते आणि त्यांची सामाजिक आणि आर्थिक स्थिती सुधारते. जोडपे त्यांच्या वैवाहिक जीवनात शांती आणि आनंद आणण्यासाठी प्रेम, सुसंवाद आणि स्टोन घालू शकतात." },

]

const Stone = () => {
    return (
        <Container fluid style={{ backgroundColor: "#f4f4f4" }}>
            <Row className='Rudraksh-row'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="ast_heading">
                        <h1><span>रत्ने</span></h1>
                        <p style={{ marginBottom: "20px" }}>
                            ज्योतिषशास्त्रातील दीपस्तंभ, जीवनाच्या प्रत्येक टप्प्यावर योग्य मार्गदर्शन, भविष्यातील अडचणींवर समाधान देणारे तज्ञ.
                        </p>
                        <strong style={{ fontSize: "1.3rem" }}>आमच्याकडे सिद्ध जप अनुष्ठान रत्न मिळतील.</strong>
                    </div>
                </div>
                {STONE_DATA?.map((item, index) => (
                    <Col key={index} sm="12" md="6" lg="3">
                        <div className="shop-item">
                            <img src={item.image} alt={item.title} className="shop-img-stone" />
                            <h3 className="shop-title">{item.title}</h3>
                            <p className="shop-desc">{item.desc}</p>
                            <h3 className="shop-price">₹ {item.price}</h3>
                            <button className='shop-button' onClick={() => window.location.href = item.paymentlink}>त्वरा करा</button>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default Stone