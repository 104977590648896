import React from 'react'
import img1 from "../images/download1.png"
import img2 from "../images/download2.png"


const DownloadApp = () => {
    return (
        <>
            <div class="ast_download_wrapper ast_toppadder70 ast_bottompadder70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>आमचे मोबाईल  <span>ॲप डाउनलोड करा</span></h1>
                                <p>आताच Play Store ला जाऊन डाउनलोड करा आणि आमच्या सेवांचा लाभ घ्या. </p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_download_box">
                                <ul>
                                    <li><a href="#"><img src={img1} alt="Download" title="Download" /></a></li>
                                    <li><a href="#"><img src={img2} alt="Download" title="Download" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadApp

