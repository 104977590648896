import React from 'react'
import mukhi4 from "../images/4-Mukhi.png"
import mukhi5 from "../images/5-mukhi.png"
import mukhi6 from "../images/6-Mukhi.png"
import mukhi7 from "../images/7-mukhi.png"
import mukhi8 from "../images/8-Mukhi.png"
import mukhi9 from "../images/9-Mukhi.png"
import mal1 from "../images/goldrudraksh.png"
import mal2 from "../images/rudrakshamalasi.png"
import mal3 from "../images/rudrakshchandi.png"
import mal4 from "../images/Mal4.png"




import { Container, Row, Col } from 'react-bootstrap'


const RUDRAKSH_DATA = [

    { paymentlink: "https://topmate.io/sagar_shastri/1098126", price: "7000", title: "४ मुखी (रुद्राक्ष)", desc: "हे माणसाला जीवनात उद्देश, कार्य आणि मोक्ष देते. त्वचारोग,मानसिक क्षमता, एकाग्रता आणि सर्जनशीलता यामध्ये विशेष फायदे आहेत.", image: mukhi4 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098130", price: "10999", title: "५ मुखी (रुद्राक्ष)", desc: "पाच मुखी खुद्द रुद्ररूप आहे आणि तो सर्व कामना पूर्ण करणारा आहे. याच्यामुळे पाप वृद्धी नाहीशी होऊन मनुष्य भक्तिमार्गाकडे वळतो. या रुद्राक्षामुळे किडनी, शुगर, लठ्ठपणा असे आजार बरे होतात.", image: mukhi5 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098131", price: "5000", title: "६ मुखी (रुद्राक्ष)", desc: "सहा मुखी रुद्राक्ष कार्तिकेय स्वरूप आहे तो उजव्या हातात धारण केला असता विशेष फल देतो. काहींच्या मते हा गणेश स्वरूपही आहे व तो उजव्या हाता बांधला असता धन, विद्या व आरोग्य देतो.", image: mukhi6 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098132", price: "9500", title: "७ मुखी (रुद्राक्ष)", desc: "सात मुखी रुद्राक्षाला अनंग म्हणतात आणि तो धारण करणार्‍याला कधीही दारिद्र्य येत नाही. याचे पूजनाने सहा मातृक, सात अश्व व सात ऋषी प्रसन्न होतात. शारीरिक दुर्बलता, उदर रोग, पक्षाघात, काळजी, हाडाचे विकार, कर्करोग, दमा, कमजोरी इतर आजार असल्यास सात मुखी रुद्राक्ष धारण केलं जातं.", image: mukhi7 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098133", price: "11999", title: "8 मुखी (रुद्राक्ष)", desc: "आठ मुखी रुद्राक्ष वसुमूर्ती भैरवरूप आहे. हा धारण करणार्‍याला भरपूर आयुष्य लाभते. अशांती, सर्पभय, त्वचा रोग, गुप्त रोग इतर आजारावर हे रुद्राक्ष धारण करण्याचा सल्ला दिला जातो.", image: mukhi8 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098138", price: "7999", title: "९ मुखी (रुद्राक्ष)", desc: "नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र", image: mukhi9 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098139", price: "5900", title: "गोल्ड रुद्राक्ष माळ ", desc: "नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र", image: mal1 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098140", price: "4999", title: "रुद्राक्ष माळ", desc: "नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र", image: mal2 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098141", price: "9999", title: "सिल्वर रुद्राक्ष माळ", desc: "नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र", image: mal3 },
    { paymentlink: "https://topmate.io/sagar_shastri/1098142", price: "9999", title: "रुद्राक्ष माळ(मोठी )", desc: "नऊ मुखी रुद्राक्ष कपिल मुनी आहे आणि त्याची अधिष्ठाची देवता दुर्गा माहेश्वरी आहे, हा रुद्राक्ष भक्तिपूर्वक डाव्या हातात धारण केला असता विशेष फल देतो. काही लोक याला स्वरूपही मानतात. फुफ्फुसासाठी, ताप, नेत्र", image: mal4 },
]

const Rudrash = () => {
    return (
        <Container fluid style={{ backgroundColor: "#fff" }}>
            <Row className='Rudraksh-row'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="ast_heading">
                        <h1><span>रुद्राक्ष ४ मुखी ते ९ मुखी व रुद्राक्ष माळा उपलब्ध</span></h1>
                        <p style={{ marginBottom: "20px" }}>
                            ज्योतिषशास्त्रातील दीपस्तंभ, जीवनाच्या प्रत्येक टप्प्यावर योग्य मार्गदर्शन, भविष्यातील अडचणींवर समाधान देणारे तज्ञ.
                        </p>
                        <strong style={{ fontSize: "1.3rem" }}>आमच्या कडे सिद्ध जप अनुष्ठान रुद्राक्ष मिळेल</strong>
                    </div>
                </div>
                {RUDRAKSH_DATA?.map((item, index) => (
                    <Col sm="12" md="6" lg="3" key={index}>
                        <div className="shop-item-ru">
                            <img src={item.image} alt={item.title} className="shop-img-rudrakh" />
                            <h3 className="shop-title">{item.title}</h3>
                            <p className="shop-desc">{item.desc}</p>
                            <h3 className="shop-price">₹ {item.price}</h3>
                            <button className='shop-button' onClick={() => window.location.href = item.paymentlink}>त्वरा करा</button>
                        </div>

                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default Rudrash