import React from 'react'
import DownloadApp from '../components/DownloadApp'
import SubTitile from '../components/SubTitile'
import review1 from '../images/review4.jpg'
import review2 from '../images/review3.jpg'
import review3 from '../images/review2.jpg'
import review4 from '../images/review1.jpg'
import review10 from '../images/havan+kund.jpg'



const Review = () => {
    return (
        <>
            <SubTitile title="पूजा माहिती" />
            <div class="ast_reviews_wrapper ast_toppadder70 ast_bottompadder70" style={{ backgroundColor: "white" }}>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading" style={{ marginTop: "30px" }}>
                                <h1><span>पूजा माहिती</span></h1>
                                <strong style={{ fontSize: "1.3rem" }}>आमच्याकडे सर्व धार्मिक पूजा अनुष्ठान करून मिळतील.</strong>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingTop: 25 }}>
                            <div class="ast_reviews_box">
                                <div class="ast_reviews_img">
                                    <a href="/"><img src={review1} alt="reviews" title="reviews" /></a>
                                </div>
                                <div class="ast_reviews_info">
                                    <h3 class="ast_reviews_info_heading" style={{ marginTop: "20px" }}><a href="/">नक्षत्र शांती.</a></h3>
                                    <p class="ast_reviews_info_details">नक्षत्र शांती म्हणजे जन्म नक्षत्रावर आधारित शांती व पूजा प्रक्रिया. यामुळे मनःशांती, मानसिक स्थिरता, आणि जीवनातील अडचणींवर मात करण्यास मदत होते. नक्षत्र शांतीने घरात समृद्धी, आरोग्य, आणि सुख शांती प्राप्त होते. जीवनातील वाईट प्रभाव कमी करण्यासाठी आणि नक्षत्रांची सकारात्मक ऊर्जा मिळवण्यासाठी ही पूजा केली जाते."</p>
                                    <a class="ast_btn" href="/">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingTop: 25 }}>
                            <div class="ast_reviews_box">
                                <div class="ast_reviews_img">
                                    <a href="/"><img src={review2} alt="reviews" title="reviews" /></a>
                                </div>
                                <div class="ast_reviews_info">

                                    <h3 class="ast_reviews_info_heading" style={{ marginTop: "20px" }}><a href="/" >कालसर्पयोग.</a></h3>
                                    <p class="ast_reviews_info_details">कालसर्पयोग शांती पूजेमुळे जीवनातील अडथळे आणि अडचणी दूर होतात. या पूजेमुळे शत्रूंवर विजय मिळतो, आणि करियर, व्यवसायात प्रगती होते. कालसर्पयोग शांतीने मनःशांती आणि आरोग्य सुधारते, तसेच घरात सुख-समृद्धी येते."</p>
                                    <a class="ast_btn" href="/">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingTop: 25 }}>
                            <div class="ast_reviews_box">
                                <div class="ast_reviews_img">
                                    <a href="/"><img src={review3} alt="reviews" title="reviews" /></a>
                                </div>
                                <div class="ast_reviews_info">

                                    <h3 class="ast_reviews_info_heading" style={{ marginTop: "20px" }}><a href="/" >नारायण नागबली.</a></h3>
                                    <p class="ast_reviews_info_details">नारायण नागबली पूजेमुळे पितृ दोष आणि नाग दोष निवारण होते. यामुळे कुटुंबातील अनिष्ट घटना आणि आर्थिक अडचणी दूर होतात. नारायण नागबलीने आरोग्य सुधारणे, मनःशांती मिळवणे, आणि घरात सुख-समृद्धी येते. या पूजेमुळे जीवनात सकारात्मक ऊर्जा आणि प्रगती मिळते."</p>
                                    <a class="ast_btn" href="/">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingTop: 25 }}>
                            <div class="ast_reviews_box">
                                <div class="ast_reviews_img">
                                    <a href="/"><img src={review10} alt="reviews" title="reviews" /></a>
                                </div>
                                <div class="ast_reviews_info">

                                    <h3 class="ast_reviews_info_heading" style={{ marginTop: "20px" }}><a href="/" >हवन</a></h3>
                                    <p class="ast_reviews_info_details">
                                  
                                      हवन म्हणजे यज्ञ किंवा अग्नि पूजन. हवनाने चांगले शारीरिक आणि मानसिक स्वास्थ्य साधता येते. यामध्ये विशेष मंत्रांच्या उच्चारणासह अग्नि मध्ये गंध, पुष्प, आणि आहुत्या दिल्या जातात. हवनाच्या प्रक्रिया आणि विविध प्रकारांच्या सहाय्याने जीवनातील अडचणी दूर करता येतात आणि आपल्या जीवनात सकारात्मकता आणता येते.
                                     </p>
                                    <a class="ast_btn" href="/">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12" style={{ paddingTop: 25 }}>
                            <div class="ast_reviews_box">
                                <div class="ast_reviews_img">
                                    <a href="/"><img src={review4} alt="reviews" title="reviews" /></a>
                                </div>
                                <div class="ast_reviews_info">
                                    <h3 class="ast_reviews_info_heading" style={{ marginTop: "20px" }}><a href="/">पितृदोष.</a></h3>
                                    <p class="ast_reviews_info_details">पितृदोष निवारण पूजेमुळे पितरांचे आशीर्वाद मिळतात आणि जीवनातील अडथळे दूर होतात. या पूजेमुळे कुटुंबातील आरोग्य सुधारते, आर्थिक स्थैर्य मिळते, आणि मनःशांती प्राप्त होते. पितृदोष निवारणामुळे घरात सुख-समृद्धी येते आणि जीवनात सकारात्मक बदल होतात."</p>
                                    <a class="ast_btn" href="/">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="ast_pagination">
                                <ul class="pagination">
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a class="active" href="#">पुढे</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Review