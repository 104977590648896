import React from 'react'
import DownloadApp from '../components/DownloadApp'
import SubTitile from '../components/SubTitile'
import timer1 from "../images/timer_1.png"
import timer2 from "../images/timer_2.png"
import timer3 from "../images/timer_3.png"
import timer4 from "../images/timer_4.png"
import tm2 from "../images/tm2.jpg"
import about1 from "../images/about.png"
import about2 from "../images/about1.png"
import ww_1 from "../images/ww_1.png"
import ww_2 from "../images/ww_2.png"
import ww_3 from "../images/ww_3.png"
import ww_4 from "../images/ww_4.png"
import ww_5 from "../images/ww_5.png"
import ww_6 from "../images/ww_6.png"

const About = () => {
    return (
        <>
            <SubTitile title="आमच्याबद्दल" />
            <div class="ast_about_wrapper ast_about_wrapper_01">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div class="ast_about_info_img">
                                <img src={about2} alt="About" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div class="ast_about_info">
                                <h4>बद्दल जाणून घ्या</h4>
                                <p>
                                    सागरनंदजी शास्त्री हे ज्योतिषशास्त्रातील आदरणीय आणि प्रसिद्ध तज्ञ आहेत. त्यांनी अनेक वर्षांच्या अभ्यास आणि अनुभवाच्या आधारावर आपल्या ज्योतिषशास्त्राच्या ज्ञानाला वाढविले आहे. त्यांच्या कुंडली विश्लेषण आणि भविष्यवाणींच्या माध्यमातून अनेक लोकांना जीवनातील अडचणींवर मात करण्यास मदत झाली आहे. वैयक्तिक, व्यावसायिक आणि आर्थिक समस्यांवर ते अचूक आणि योग्य सल्ला देतात.
                                </p>
                                <p>
                                    सागरनंदजी शास्त्री यांच्या मार्गदर्शनाने आपल्या भविष्याच्या अनिश्चिततेतून बाहेर पडता येईल. ते आपल्या समस्यांचे मूळ शोधून काढून त्यावर उपाय योजना सुचवतात. त्यांच्या शास्त्रीय दृष्टिकोनामुळे, तुम्हाला विश्वासार्ह आणि परिणामकारक मार्गदर्शन मिळते. सागरनंदजी शास्त्री हे केवळ भविष्य सांगणारे नाहीत, तर आपल्या जीवनाच्या प्रवासात एक सच्चे मार्गदर्शक आहेत.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ast_whywe_wrapper ast_toppadder70 ast_bottompadder70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>आम्हाला<span> का निवडा</span></h1>
                                <p>
                                    ज्योतिषशास्त्रातील दीपस्तंभ, जीवनाच्या प्रत्येक टप्प्यावर योग्य मार्गदर्शन, भविष्यातील अडचणींवर समाधान देणारे तज्ञ.
                                </p>
                            </div>
                        </div>
                        <div class="ast_whywe_info row">
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_1} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>10+ अनुभव</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_2} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>24x7, 365 दिवसांची उपलब्धता</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_3} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>जगभरात झटपट प्रवेश</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_4} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>अचूक उपचार उपाय</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_5} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>गोपनीयतेची हमी</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div class="ast_whywe_info_box">
                                    <span><img src={ww_6} alt="" /></span>
                                    <div class="ast_whywe_info_box_info">
                                        <p>दशलक्ष ग्राहकांद्वारे विश्वासार्ह</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ast_timer_wrapper ast_toppadder70 ast_bottompadder40">
                <div class="ast_img_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>आता <span> आमच्याकडे आहे</span></h1>
                                <p>
                                    आपल्या भविष्याच्या रहस्यांचा उजेड करणारे जन्म पत्रिका, ज्योतिषाच्या माध्यमातून आपल्या जीवनाच्या मार्गावर अग्रसर होण्याचा निश्चित मार्गदर्शन.
                                </p>
                            </div>
                        </div>
                        <div class="ast_counter_wrapper row">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer1} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="2"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>कार्यालये</h4>

                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer2} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="100"
                                        data-speed="5000"
                                    ></h2><h4>कुशल ज्योतिषी</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer3} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="60"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>देश</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer4} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="10"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>अनुभवांची वर्षे</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ast_testimonial_wrapper ast_toppadder70 ast_bottompadder70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>लोकांच्या <span> प्रतिक्रिया</span></h1>
                                <p>
                                    लोकांच्या प्रतिक्रिया त्यांच्या मनाला दाखवते आणि सामाजिक संवादात विशेष महत्व आहे. प्रतिक्रिया या माध्यमातून समजून घ्यायच्या आणि सुधारणात योगदान करण्यात मदत करते.
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-lg-10 col-md-12 col-sm-12 col-12 offset-lg-1 offset-md-0 offset-sm-0 offset-0"
                        >
                            <div class="ast_testimonials_slider">
                                <div class="owl-carousel owl-theme">
                                    <div class="item">
                                        <div class="ast_testimonials_slider_box">
                                            <img src={tm2} alt="Testimonial" />
                                            <div class="ast_testimonials_slider_box_text">
                                                <p>
                                                    तुमची सेवा खूप उत्तम आणि प्राविण्याने केली. आपल्या मदतीने मला खूप मदत झाली. धन्यवाद!
                                                </p>
                                                <h4>आरती गावणे</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ast_testimonials_slider_box">
                                            <img src={tm2} alt="Testimonial" />
                                            <div class="ast_testimonials_slider_box_text">
                                                <p>
                                                    तुमची सेवा सर्वोत्तम आणि तुमची मदद खूप आवडली. आपल्या सेवेला १००% सुचला.
                                                </p>
                                                <h4>माधव शिर्के</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ast_testimonials_slider_box">
                                            <img src={tm2} alt="Testimonial" />
                                            <div class="ast_testimonials_slider_box_text">
                                                <p>
                                                    मी तुमची सेवा खूप आवडली. संपर्क सुचला जवळची सवय मिळेल
                                                </p>
                                                <h4>विद्या देशमुख</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="ast_testimonials_slider_box">
                                            <img src={tm2} alt="Testimonial" />
                                            <div class="ast_testimonials_slider_box_text">
                                                <p>
                                                    खूप छान सेवा! मला खूप आनंद आला आपल्या सेवेशी. धन्यवाद!
                                                </p>
                                                <h4>सुरेश पाटील,</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DownloadApp />


        </>
    )
}

export default About