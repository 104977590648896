import React from 'react'

const SubTitile = ({ title }) => {
    return (
        <div class="ast_pagetitle">
            <div class="ast_img_overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="page_title">
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <ul class="breadcrumb">
                            <li><a href="index.html">मुख्यापुष्ठ</a></li>
                            <li>//</li>
                            <li><a href="contact.html">{title}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubTitile