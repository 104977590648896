import React from 'react'

const Footer = () => {
    return (
        <div className="ast_footer_wrapper ast_toppadder70 ast_bottompadder20">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="ast_footer_info">
                            {/* <img src="images/header/logo.png" alt="Logo" /> */}
                            <p>सागरनंदजी शास्त्री हे आपल्या जीवनाचा मार्गदर्शन करणारे एक विश्वसनीय ज्योतिष आहेत. त्यांच्या शास्त्रीय ज्ञानाच्या आधारे, ते तुमच्या भविष्याचा अभ्यास करून सर्व प्रश्नांचे समाधान देतात. जीवनाच्या प्रत्येक टप्प्यावर, सागरनंदजी शास्त्री यांच्याकडून मिळवा योग्य मार्गदर्शन आणि मार्गदर्शन मिळवा.</p>
                            <ul>
                                <li><a href="#"><i class="bi bi-facebook"></i></a></li>
                                <li><a href="#"><i class="bi bi-google"></i></a></li>
                                <li><a href="#"><i class="bi bi-pinterest"></i></a></li>
                                <li><a href="#"><i class="bi bi-skype"></i></a></li>
                                <li><a href="#"><i class="bi bi-twitter-x"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="widget text-widget">
                            <h4 className="widget-title">आमचे वृत्तपत्र</h4>
                            <div className="ast_newsletter">
                                <p>आपल्या जीवनाचा मार्गदर्शक, सागरनंदजी शास्त्री.सर्व प्रश्नांचे समाधान, सागरनंदजी शास्त्री यांच्या ज्योतिषशास्त्रात."</p>
                                <div className="ast_newsletter_box">
                                    <input type="text" placeholder="ई-मेल" />
                                    <button type="submit"><i class="bi bi-send"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                        <div className="widget text-widget">
                            <h4 className="widget-title">आमच्या सेवा</h4>
                            <div className="ast_servicelink">
                                <ul>
                                    <li><a href="/service">कुंडली</a></li>
                                    <li><a href="/service">रत्ने</a></li>
                                    <li><a href="/service">रुद्राक्ष</a></li>
                                    <li><a href="/service">यंत्रे</a></li>
                                    <li><a href="/service">जन्म पत्रिका</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                        <div className="widget text-widget">
                            <h4 className="widget-title">लगेच पोहचा</h4>
                            <div className="ast_sociallink">
                                <ul>
                                    <li><a href="/about">आमच्याबद्दल</a></li>
                                    <li><a href="/review">पूजा माहिती</a></li>
                                    <li><a href="/service">उत्पादने</a></li>
                                    <li><a href="/contact">आमच्याशी संपर्क साधा</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="widget text-widget">
                            <h4 className="widget-title">संपर्कात रहाण्यासाठी</h4>
                            <div className="ast_gettouch">
                                <ul>
                                    <li><i class="bi bi-house"></i>
                                        <p>नाशिक त्र्यंबकेश्वर गुरुपीठ</p>
                                    </li>
                                    <li><i class="bi bi-envelope-arrow-down"></i> <a href="mailto:sj1030056@gmail.com">sj1030056@gmail.com</a></li>
                                    <li><i class="bi bi-telephone"></i>
                                        <p>7558658385</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="ast_copyright_wrapper">
                            <p>&copy; कॉपीराइट  {new Date().getFullYear()},Gurumauli Jyotish Gurupeeth , सर्व हक्क राखीव, <a href="#">सागरनंदजी शास्त्री</a></p>
                            <p>द्वारे विकसित  <a href="#">Shree Digital Marketing Agency</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer