import React from 'react'
import DownloadApp from '../components/DownloadApp'
import SubTitile from '../components/SubTitile'
import Yantra from '../components/Yantra'
import Rudrash from '../components/Rudrash'
import Stone from '../components/Stone'

const Shop = () => {

    return (
        <>
            <SubTitile title="खरेदी करा" />
            <Yantra />
            <Stone />
            <Rudrash />
            <DownloadApp />

        </>
    )
}

export default Shop