import React from 'react'
import shop1 from "../images/shop1.jpg"
import shop2 from "../images/shop2.jpg"
import shop3 from "../images/shop3.jpg"
import shop4 from "../images/shop4.jpg"
import shop5 from "../images/shop5.jpg"
import shop6 from "../images/shop6.jpg"
import shop7 from "../images/shop7.jpg"
import shop8 from "../images/shop8.jpg"
import { Container, Row, Col } from 'react-bootstrap'



const YANTRE_DATA = [

    { paymentlink: "https://topmate.io/sagar_shastri/1098046", title: "श्री सम्पूर्ण सर्वकष्ट निवारण यंत्र", image: shop2, price: "6999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098073", title: "श्री व्यापार वृद्धि यंत्र", image: shop1, price: "7999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098077", title: "सर्व बाधा मुक्ति यंत्र", image: shop3, price: "5999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098078", title: "श्री संपूर्ण नवग्रह यंत्र", image: shop4, price: "4900" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098080", title: "श्री नवग्रह यंत्रम", image: shop5, price: "11999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098081", title: "सर्व रक्षा महा यंत्र", image: shop1, price: "9999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098083", title: "श्री सम्पूर्ण यंत्र", image: shop2, price: "3999" },
    { paymentlink: "https://topmate.io/sagar_shastri/1098077", title: "सर्व बाधा मुक्ति यंत्र", image: shop3, price: "5999" },
]



const Yantra = () => {
    return (
        <Container fluid style={{ backgroundColor: "#fff" }}>
            <Row className='yantre-row'>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="ast_heading">
                        <h1><span>यंत्रे</span></h1>
                        <p style={{ marginBottom: "20px" }}>
                            ज्योतिषशास्त्रातील दीपस्तंभ, जीवनाच्या प्रत्येक टप्प्यावर योग्य मार्गदर्शन, भविष्यातील अडचणींवर समाधान देणारे तज्ञ.
                        </p>
                        <strong style={{ fontSize: "1.3rem" }}>आमच्याकडे सिद्ध जप अनुष्ठान झालेले यंत्र मिळतील</strong>
                    </div>
                </div>
                {YANTRE_DATA?.map((item, index) => (
                    <Col key={index} sm="12" md="6" lg="3">
                        <div className="shop-item">
                            <img src={item.image} alt={item.title} className="shop-img" />
                            <h3 className="shop-title">{item.title}</h3>
                            <h3 className="shop-price">₹ {item.price}</h3>
                            <button className='shop-button' onClick={() => window.location.href = item.paymentlink}>त्वरा करा</button>
                        </div>
                    </Col>

                ))}
            </Row>
        </Container>
    )
}

export default Yantra