import React from 'react'
import { Link } from "react-router-dom"

const Header = () => {
    return (
        <>
            <div class="ast_top_header" style={{ backgroundColor: "#f7e7d7" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_contact_details">
                                <ul>
                                    <li>
                                        <i class="bi bi-telephone-fill"></i> 7558658385
                                    </li>
                                    <li>
                                        <a href="mailto:sj1030056@gmail.com"
                                        ><i class="bi bi-envelope"></i>
                                            sj1030056@gmail.com</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ast_header_bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-10">
                            <div class="ast_logo">
                                <a href="/" style={{ textDecoration: "none" }}><h3 style={{ color: "#ff6f00", fontWeight: "bold" }}>Shree Samarth Jyotisha Kendra</h3></a>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-2">
                            <div class="ast_main_menu_wrapper">
                                <div class="ast_menu">
                                    <ul>
                                        <li>
                                            <a class="dropdown-toggle" style={{ textDecoration: "none" }} href="/">मुख्यापुष्ठ</a>
                                        </li>
                                        <li><a href="/about" style={{ textDecoration: "none" }}>आमच्याबद्दल</a></li>
                                        <li><a href="/service" style={{ textDecoration: "none" }}>सेवा</a></li>
                                        <li >
                                            <a class="dropdown-toggle" href="/review" style={{ textDecoration: "none" }}>पूजा माहिती</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-toggle" href="/shop" style={{ textDecoration: "none" }}>खरेदी करा</a>
                                        </li>
                                        <li><a href="/contact" style={{ textDecoration: "none" }}>आमच्याशी संपर्क साधा</a></li>
                                    </ul>
                                </div>
                            </div>
                            <button class="ast_menu_btn">
                                <i class="bi bi-menu-app" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header