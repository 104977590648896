import React from 'react'
import DownloadApp from '../components/DownloadApp'
import SubTitile from '../components/SubTitile'

const Contact = () => {
    return (
        <>
            <SubTitile title="आमच्याशी संपर्क साधा" />
            <div class="ast_contact_wrapper ast_toppadder70 ast_bottompadder50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>संपर्कात  <span>रहा</span></h1>
                                <p>आपल्या समस्या, विचारे, किंवा आवश्यकतांसाठी आमच्याशी संपर्क साधा, आपल्या मदतीसाठी आम्ही तयार आहोत.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="ast_contact_info">
                                <span><i class="bi bi-telephone-inbound-fill"></i></span>
                                <h4>फोन</h4>
                                <p>7558658385</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="ast_contact_info">
                                <span><i class="bi bi-envelope"></i></span>
                                <h4>ई-मेल</h4>
                                <p><a href="mailto:sj1030056@gmail.com">sj1030056@gmail.com</a></p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="ast_contact_info">
                                <span><i class="bi bi-geo-alt-fill"></i></span>
                                <h4>पत्ता</h4>
                                <p>नाशिक त्र्यंबकेश्वर गुरुपीठ</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ast_mapnform_wrapper ast_toppadder70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>येथे शोधा  <span>आणि संदेश द्या</span></h1>
                                <p>संपर्क सुरु करा, आमच्याशी संपर्क साधा आणि आपल्या प्रश्नांवर उत्तर प्राप्त करा.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ast_contact_map">
                    <div class="ast_contact_form">
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>पहिले नाव</label>
                                    <input type="text" name="first_name" class="require" />
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>शेवटचे नाव</label>
                                    <input type="text" name="last_name" class="require" />
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>पत्ता </label>
                                    <input type="text" name="subject" class="require" />
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>समस्या</label>
                                    <textarea rows="5" name="message" class="require"></textarea>
                                </div>
                                <div class="response"></div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <button class="ast_btn pull-right submitForm" type="button" form-type="contact">पाठवा</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8401.560921854018!2d73.53536172985781!3d19.93801237820021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddf4b167cecc65%3A0x3b8e4b5e1a81d285!2sAkhil%20Bhartiya%20Shree%20Swami%20Samarth%20Gurupeeth%2C%20Trimbakeshwar!5e1!3m2!1sen!2sin!4v1720931721001!5m2!1sen!2sin"
                        allowfullscreen></iframe>
                </div>
            </div >
            <DownloadApp />

        </>
    )
}

export default Contact