import React from 'react'
import DownloadApp from '../components/DownloadApp'
import SubTitile from '../components/SubTitile'
import sv1 from '../images/sv1.jpg'
import sv2 from '../images/sv2.jpg'
import sv3 from '../images/sv3.png'
import sv4 from '../images/sv4.png'
import sv5 from '../images/sv5.png'
import sv6 from '../images/sv6.png'
import sv7 from '../images/sv7.jpg'
import sv8 from '../images/sv8.jpg'
import sv9 from '../images/sv9.jpg'
import sv10 from '../images/Kundli-Match-Making-400x270.jpg'
import timer1 from "../images/timer_1.png"
import timer2 from "../images/timer_2.png"
import timer3 from "../images/timer_3.png"
import timer4 from "../images/timer_4.png"
import vad1 from "../images/vad_1.png"
import vad2 from "../images/vad_2.png"
import vad3 from "../images/vad_3.png"
import vad4 from "../images/vad_4.png"
import vad5 from "../images/vad_5.png"
import vad6 from "../images/vad_6.png"
import vad7 from "../images/vad_7.png"
import vad8 from "../images/vad_8.png"
import vas1 from "../images/vas_1.png"
import vas2 from "../images/vas_2.png"
import vas3 from "../images/vas_3.png"
import vas4 from "../images/vas_4.png"
import vas5 from "../images/vas_5.png"
import vas6 from "../images/vas_6.png"
import vdk from "../images/vdk_bb.jpg"
import vdk_kndl from "../images/vdk_kndl.png"
import vdk_vst from "../images/vdk_vst.jpg"









const Service = () => {
    return (
        <>
            <SubTitile title="सेवा" />
            <div class="ast_service_wrapper ast_toppadder70 ast_bottompadder50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv5} alt="Service" />
                                <h4>जन्म पत्रिका</h4>
                                <p>
                                    जन्म पत्रिका आपल्या भविष्याचे अचूक चित्र देते. सागरनंदजी शास्त्री यांच्या मार्गदर्शनाने, आपल्या जीवनाचे गूढ उकलून घ्या.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv6} alt="Service" />
                                <h4>वास्तुशास्त्र</h4>
                                <p>
                                    वास्तुशास्त्र आपल्या घराचे सौख्य आणि समृद्धी वाढवते. सागरनंदजी शास्त्री यांच्या वास्तुशास्त्र ज्ञानाने घराला शुभता मिळवा.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv9} alt="Service" />
                                <h4>कोर्ट केस</h4>
                                <p>
                                    कोर्ट केसच्या माध्यमातून आपल्या व्यक्तिमत्वाचे गुप्त रहस्य उघडायला मदत करते. सागरनंदजी शास्त्री यांच्या तत्वज्ञानाने, आपल्या चेहऱ्यावर सत्य व्यक्त होणारे असल्याचं शोधा.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv3} alt="Service" />
                                <h4>प्रोमोशन</h4>
                                <p>
                                    प्रोमोशन एक अद्वितीय ज्योतिष ग्रंथ आहे ज्यातील उपाये आपल्याला भविष्याच्या समस्यांवर समाधान करतात. सागरनंदजी शास्त्री यांच्या मार्गदर्शनाने, प्रोमोशनच्या सल्ल्यांना भरपूर मान्यता मिळवा.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv2} alt="Service" />
                                <h4>संतान प्राप्ती</h4>
                                <p>
                                    संतान प्राप्ती एक अद्वितीय तरोत्तार चिकित्साक्रिया आहे ज्यामध्ये ज्योतिषाच्या सहाय्याने भविष्याचा दिव्य दृष्टीकोन प्राप्त करता येतो. सागरनंदजी शास्त्री यांच्या मार्गदर्शनाने, संतान प्राप्तीच्या सहाय्याने आपल्या भविष्याच्या विविध आवडतांची जाणीव घ्या.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv1} alt="Service" />
                                <h4>कुंडली दोष</h4>
                                <p>
                                    कुंडली दोष आपल्या जीवनातल्या समस्यांच्या कारणे आणि त्यांचा समाधान करण्याची विशेष मार्गदर्शन करते. सागरनंदजी शास्त्री यांच्या ज्योतिषशास्त्राने, कुंडलीच्या दोषांवर विचार करून सर्व समस्यांचे समाधान करा.
                                </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv7} alt="Service" />
                                <h4>वर्षाचे विश्लेषण</h4>
                                <p>वर्षाचे विश्लेषण आपल्या आर्थिक, सामाजिक आणि वैयक्तिक दृष्टीकोनातून महत्वाचं आहे. ह्या विश्लेषणाने आपल्या वार्षिक साधारण विकास, संघर्ष आणि सामर्थ्याचा विचार केला जातो.</p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv8} alt="Service" />
                                <h4>विवाह</h4>
                                <p>विवाह हे एक जीवनाचं महत्वपूर्ण संघर्ष आहे, ज्यातून सामाजिक, आर्थिक आणि व्यक्तिगत समृद्धीसाठी सामर्थ्याचा उपयोग केला जातो. या संघर्षानंतर, व्यक्तीच्या जीवनात आपल्या सहजीवनाची समृद्धी आणि स्थिरता येईल.</p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv10} alt="Service" />
                                <h4>कुंडली मिलान</h4>
                                <p>कुंडली मिलान हा भारतीय विवाह पद्धतीत एक महत्वपूर्ण प्रक्रिया आहे, ज्यात दोन व्यक्तींच्या कुंडलींचा मिलान करून त्यांच्या सुसंगतीचा आढावा घेतला जातो..</p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="ast_service_box">
                                <img src={sv4} alt="Service" />
                                <h4>लव्ह प्रोंबलेम</h4>
                                <p>लव्ह प्रोंबलेम हे एक सेवा आहे ज्यामध्ये वस्तू आणि सेवांच्या विनिमयासाठी धन किंवा चालू चाललेल्या मूल्याची आपत्ती ठेवली जाते. </p>
                                <div class="clearfix"></div>
                                <a href="/" class="ast_btn">पुढे वाचा</a>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
            </div>

            <div class="ast_wedo_wrapper ast_toppadder70 ast_bottompadder50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>दैनंदिन <span> दिनचर्या</span></h1>
                                <p>
                                    ज्योतिषाच्या आलोकातून आपल्या जीवनाला प्रकाश देणारे, सुख, शांती आणि समृद्धीच्या दिशेने सर्वसाधारणांना मार्गदर्शन करणारे तज्ञ.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="ast_vedic_astrology">
                                                <h4>नक्षत्रानुसार बाळाची नावे</h4>
                                                <div class="ast_vedic_astro_box">
                                                    <img src={vdk} alt="Baby" />
                                                    <p>
                                                        नक्षत्रांनुसार, बाळांना नावे दिल्यानंतर त्यांच्या व्यक्तिमत्वावर आणि भविष्याच्या प्रभावावर सांगतात. प्रत्येक नक्षत्राच्या विशेषतेनुसार, बाळांना विविध गुण आणि प्रकृती मिळतात ज्यांमध्ये वेरोनिका
                                                    </p>
                                                    <a href="#" class="ast_btn pull-right">आता शोधा</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="ast_vedic_astrology">
                                                <h4>वैदिक ज्योतिष</h4>
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas1} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>सूर्य राशी</h5>
                                                                <p>
                                                                    सूर्य राशी आपल्या जन्मराशीच्या प्रभावानुसार आपल्या व्यक्तिमत्वाची समज घेते आणि मार्गदर्शन करते
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas2} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>चंद्र राशी</h5>
                                                                <p>
                                                                    चंद्र राशी आपल्या भावनांच्या गहन अध्ययनाच्या माध्यमातून आपल्या जीवनाच्या समस्यांवर समाधान करण्याचे साधन करते
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas3} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>ग्रह</h5>
                                                                <p>
                                                                    ग्रह आपल्या जीवनातल्या प्रत्येक पहा वाचा
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas4} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>चढत्या</h5>
                                                                <p>
                                                                    कुंडली दोष आपल्या जीवनातल्या समस्यांच्या कारणे आणि त्यांचा समाधान करण्याची विशेष मार्गदर्शन करते.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas5} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>बारा घरे</h5>
                                                                <p>
                                                                    बारा घरे ज्योतिषशास्त्रानुसार आपल्या जीवनातल्या विविध क्षेत्रांवर विचार करून समझल्या जातात
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vas6} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>नक्षत्र</h5>
                                                                <p>
                                                                    नक्षत्र आपल्या जन्मकुंडलीतील महत्वपूर्ण घटक आहेत, ज्यांच्या माध्यमातून आपल्या व्यक्तिमत्वाची अगदी चांगली अध्ययन केली जाते.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="ast_vedic_astrology">
                                                <h4>जन्म पत्रिका (कुंडली)</h4>

                                                <div class="ast_vedic_astro_box">
                                                    <img src={vdk_kndl} alt="Baby" />
                                                    <p>
                                                        जन्म पत्रिका, ज्योतिषशास्त्राचा एक महत्वपूर्ण भाग आहे, ज्यातून व्यक्तीच्या जन्माच्या समयानुसार ग्रहांच्या स्थितीचा विश्लेषण केला जातो. ह्या पत्रिकेतील विविध घटकांचे माध्यमातून व्यक्तीच्या भविष्याचा अभ्यास केला जातो, जसे की व्यक्तिमत्त्याचा विकास, करिअर, स्वास्थ्य, वैवाहिक आणि पालक प्रतिकर्षांचे उल्लेख आणि सल्ले केले जातात. या ज्ञानातून व्यक्ती आपल्या जीवनाच्या विविध क्षेत्रांवर समझून घेऊ शकतो आणि सुसंवाद साधून आपल्या जीवनाच्या निर्णयांमध्ये सर्वचाहत्यांच्या मार्गावर अग्रसर राहू शकतो.
                                                    </p>
                                                    <a href="appointment.html" class="ast_btn pull-right">आता तपासा</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="ast_vedic_astrology">
                                                <h4>ज्योतिष सल्ला</h4>
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad1} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>करिअर</h5>
                                                                <p>
                                                                    खरं, मी आपल्याला मदत करू शकतो. आपल्या करिअरबद्दल काही विशेष सवाल आहेत का?
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad2} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>नाते</h5>
                                                                <p>
                                                                    नाते आपल्या जीवनातल्या सर्वांसह संवाद साधतात आणि व्यक्तिमत्त्यांच्या विकासात मदत करतात. चांगल्या नात्यांच्या आधारावर, आपल्या जीवनात संतुलन आणि समृद्धी येईल.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad3} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>वित्त</h5>
                                                                <p>
                                                                    वित्तीय स्थिती आपल्या जीवनाच्या मुख्य घटक आहे, ज्यामध्ये नियंत्रण आणि योजना करणे महत्वाचं आहे। उत्तम वित्तीय व्यवस्थापन आपल्याला आर्थिक स्थिरता आणि समृद्धीसाठी मार्गदर्शन करते.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad4} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>व्यवसाय</h5>
                                                                <p>
                                                                    व्यवसाय आपल्या स्वावलंबनाच्या आधारावर आणि व्यापारिक योजनांच्या माध्यमातून आपल्या उच्च आणि विचारशीलतेला प्रकट करतो. एकमेकांच्या संबंधांच्या माध्यमातून, व्यवसाय आपल्या यशाच्या मार्गावर अग्रसर होतो.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad5} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>शिक्षण</h5>
                                                                <p>
                                                                    शिक्षणाच्या मार्गाने समग्र विकास आणि सामाजिक सुधारणा होते.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad6} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>आरोग्य</h5>
                                                                <p>
                                                                    आरोग्य हे जीवनातील सर्वांसाठी महत्वपूर्ण आहे, ज्यात स्वस्थ रहायचं आणि नियमित व्यायाम, आहार आणि आरोग्यपर्यंत संवाद ठेवायचं आहे। त्याच्यामाध्ये सातत्याने संरक्षण करण्यासाठी सर्व शक्य प्रयत्न करा.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad7} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>प्रश्न विचारा</h5>
                                                                <p>
                                                                    प्रश्न विचारण्याचा माध्यम विचारशीलतेला अद्वितीय दृष्टिकोन देतो आणि ज्ञानाच्या मार्गावर आग्रही घेतो.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src={vad8} alt="Sign" />
                                                            <div class="ast_vedic_astro_info">
                                                                <h5>प्रेम उपाय</h5>
                                                                <p>
                                                                    प्रेमाच्या वातावरणात शांतता आणि समजूतीच्या वातावरणात निर्माण करा. संवादात औचित्याचा वापर करून, प्रेमाच्या अनुभवात अतिरेक करा.
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="ast_vedic_astrology">
                                                <h4>वास्तुशास्त्र</h4>

                                                <div class="ast_vedic_astro_box">
                                                    <img src={vdk_vst} alt="vastu" />
                                                    <p>
                                                        वास्तुशास्त्र हे एक प्राचीन विज्ञान आहे ज्यामध्ये घराच्या निर्माणाच्या विविध पहिल्या टप्प्यांचे मार्गदर्शन केले जाते. या शास्त्राच्या माध्यमातून घराच्या वास्तविकतेचा आणि अंतर्ज्ञानाचा अध्ययन केला जातो. वास्तुशास्त्रानुसार, योग्य वास्तुदोष निवारण करण्याचे औचित्याचे सल्ले दिले जाते आणि घराच्या स्थानाच्या सुधारणा केल्या जातात.
                                                    </p>
                                                    <a href="/" class="ast_btn pull-right">आता तपासा</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ast_timer_wrapper ast_toppadder70 ast_bottompadder40">
                <div class="ast_img_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="ast_heading">
                                <h1>आता <span> आमच्याकडे आहे</span></h1>
                                <p>
                                    आपल्या भविष्याच्या रहस्यांचा उजेड करणारे जन्म पत्रिका, ज्योतिषाच्या माध्यमातून आपल्या जीवनाच्या मार्गावर अग्रसर होण्याचा निश्चित मार्गदर्शन.
                                </p>
                            </div>
                        </div>
                        <div class="ast_counter_wrapper row">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer1} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="2"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>कार्यालये</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer2} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="100"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>कुशल ज्योतिषी</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer3} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="60"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>देश</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="ast_counter">
                                    <span
                                    ><img src={timer4} alt="timer"
                                        /></span>
                                    <h2
                                        class="timer"
                                        data-from="0"
                                        data-to="10"
                                        data-speed="5000"
                                    ></h2>
                                    <h4>अनुभवांची वर्षे</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DownloadApp />


        </>
    )
}

export default Service